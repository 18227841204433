var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane active",attrs:{"id":"plans"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('router-link',{staticClass:"btn btn-success btn-floating",attrs:{"to":{ name: 'AttachPlan', params: { id: _vm.customer.id } }}},[_c('i',{staticClass:"fas fa-plus-circle text-white font-size-22 v-align-text-bottom"})])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('data-table',{attrs:{"columns":[
            {
              key: 'service',
              class: 'badge badge-primary',
              label: 'product',
            },
            'name',
            {
              key: 'pivot.pricing.fee',
              label: 'price',
              filters: ['currency'],
            },
          ],"data":_vm.plans},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('dropdown',{scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-h"})]},proxy:true},{key:"items",fn:function(){return [_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.destroyPlan(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('app.cancel'))+" ")])]},proxy:true}],null,true)})]}}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }